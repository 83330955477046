@import "@openedx/paragon/scss/core/core";

.course-list-column {
  padding: 0 map-get($spacers, 4);
}

.sidebar-column {
  padding: 0 map-get($spacers, 3) 0 map-get($spacers, 1);
}

@include media-breakpoint-down(lg) {
  .sidebar-column {
    // grid are inheriting dir="ltr" from the body, so we need to override it
    [dir=ltr] & {
      padding: 0 map-get($spacers, 3);
    }
    [dir=rtl] & {
      padding: 0 map-get($spacers, 3);
    }
  }
}