@import "@openedx/paragon/scss/core/core";

#no-courses-content-view {
  border: 2px solid $light-400;
  flex-direction: column;
  padding-bottom: map-get($spacers, 5);
  padding-top: map-get($spacers, 5);
  height: 100%;

  & > * {
    margin-top: map-get($spacers, 3);
    margin-bottom: map-get($spacers, 3);
  }
}

