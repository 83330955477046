.dropdown-menu-collapse {
  width: 100vw;
  position: absolute;
  left: 0;
}

.learner-variant-header {
  a {
    // needed to make the link not resize the header
    border-bottom: 2px solid transparent;
  }
  .course-link {
    border-bottom: 2px solid !important;
  }

  .course-link:hover {
    border-bottom: inherit !important;
  }
}

.nav-small-menu {
  > * {
    justify-content: flex-start !important;

    border-radius: 0 !important;
    border-top: 1px solid #ddd !important;

    &::after {
      content: '\00BB';
      padding-left: 10px;
    }
  }
}

.logo {
  // copy from legacy dashboard
  height: 40px;
}
